import React from "react";

class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <h1>About Page</h1>
        <p>Placeholder for about page</p>
      </div>
    );
  }
}

export default AboutPage;
